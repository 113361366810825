import React, { useState } from "react";
import { FaEnvelope, FaLinkedin, FaGithub } from "react-icons/fa";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid2,
  Container,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import emailjs from "emailjs-com";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Contact() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setError("");

    setLoading(true);

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setStatus("Message sent successfully!");
          setOpenSnackbar(true);
        },
        (error) => {
          console.error("FAILED...", error);
          setStatus("Failed to send message. Please try again.");
          setOpenSnackbar(true);
        }
      )
      .finally(() => {
        setLoading(false);
        setEmail("");
        setName("");
        setMessage("");
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "black",
        padding: "50px 20px",
        boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        zIndex: "11",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        sx={{ color: "white", marginBottom: "60px", marginTop: "40px" }}
      >
        Let's Connect!
      </Typography>

      <Grid2
        container
        spacing={5}
        justifyContent="center"
        sx={{ marginBottom: "40px" }}
      >
        <Grid2 item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FaEnvelope
              size="1.5em"
              style={{ marginRight: "10px", color: "white" }}
            />
            <a
              href="mailto:danaildimitrovwork01@gmail.com"
              style={{
                color: "white",
                textDecoration: "none",
                fontSize: "20px",
              }}
            >
              danaildimitrovwork01@gmail.com
            </a>
          </Box>
        </Grid2>

        <Grid2 item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FaLinkedin
              size="1.5em"
              style={{ marginRight: "10px", color: "white" }}
            />
            <a
              href="https://www.linkedin.com/in/danail-dimitrov-78b265257/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "white",
                textDecoration: "none",
                fontSize: "20px",
              }}
            >
              linkedin.com
            </a>
          </Box>
        </Grid2>

        <Grid2 item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FaGithub
              size="1.5em"
              style={{ marginRight: "10px", color: "white" }}
            />
            <a
              href="https://github.com/Danailnd"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "white",
                textDecoration: "none",
                fontSize: "20px",
              }}
            >
              github.com/Danailnd
            </a>
          </Box>
        </Grid2>
      </Grid2>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            label="Enter Your Name"
            variant="outlined"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                color: "grey",
                "&.Mui-focused": {
                  color: "#FF204E",
                },
              },
              marginBottom: "20px",
              backgroundColor: "white",
              borderRadius: "4px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gray",
                },
                "&:hover fieldset": {
                  borderColor: "#FF204E",
                },
              },
            }}
          />
          <TextField
            name="email"
            label="Your Email"
            variant="outlined"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                color: "grey",
                "&.Mui-focused": {
                  color: "#FF204E",
                },
              },
              marginBottom: "20px",
              backgroundColor: "white",
              borderRadius: "4px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gray",
                },
                "&:hover fieldset": {
                  borderColor: "#FF204E",
                },
              },
            }}
          />
          {error && (
            <Typography
              variant="body2"
              component="p"
              sx={{ color: "red", marginBottom: "10px" }}
            >
              {error}
            </Typography>
          )}
          <TextField
            name="message"
            label="Message"
            variant="outlined"
            fullWidth
            required
            multiline
            rows={5}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                color: "grey",
                "&.Mui-focused": {
                  color: "#FF204E",
                },
              },
              marginBottom: "20px",
              backgroundColor: "white",
              borderRadius: "4px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gray",
                },
                "&:hover fieldset": {
                  borderColor: "#FF204E",
                },
              },
            }}
          />

          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{
              backgroundColor: "#FF204E",
              "&:hover": {
                backgroundColor: "#ff4567",
              },
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Send Message"
            )}
          </Button>
        </form>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={status.includes("successfully") ? "success" : "error"}
          >
            {status}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
}

export default Contact;
