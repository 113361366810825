import React from "react";
import TracingCanvas from "tracing-canvas";

function AboutMe() {
  return (
    <>
      <TracingCanvas
        style={{
          cursor: "none",
          width: "100%",
          height: "800px",
          position: "relative",
        }}
        options={{
          drawingColor: "#000000",
          backgroundColor: "#ffffff",
          customFrameRate: 2,
          rootCount: 10,
          branchIterations: 3,
          branchStrokeWeight: 0.9,
        }}
      >
        <>
          <h2
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "#FF204E",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            I am ...
          </h2>
          <div
            style={{
              position: "absolute",
              top: "250px",
              left: "10%",
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              padding: "20px",
              border: "1px solid #ccc",
              zIndex: "20",
              boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
              borderRadius: "8px",
              maxWidth: "500px",
              cursor: "default",
            }}
          >
            <h2
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#333",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              ... a Developer
            </h2>
            <p
              style={{
                fontSize: "18px",
                color: "#555",
                lineHeight: "1.5",
                textAlign: "justify",
              }}
            >
              I write clean, efficient, and performant code. I'm well-versed in
              the most common security vulnerabilities and always keep my work
              to high standards.
            </p>
          </div>
        </>
      </TracingCanvas>
      <TracingCanvas
        style={{
          cursor: "none",
          width: "100%",
          height: "800px",
          boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
          customFrameRate: 2,
          rootCount: 10,
          branchIterations: 3,
          branchStrokeWeight: 0.9,
          zIndex: "14",
        }}
        options={{
          invertedFade: true,
          backgroundColor: "#000000",
          drawingColor: "#FFFFFF",
          mode: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "250px",
            right: "15%",
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            padding: "20px",
            border: "1px solid #ccc",
            zIndex: "20",
            boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            maxWidth: "500px",
            cursor: "default",
          }}
        >
          <h2
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "#333",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            ... a Designer
          </h2>
          <p
            style={{
              fontSize: "18px",
              color: "#555",
              lineHeight: "1.5",
              textAlign: "justify",
            }}
          >
            I leverage my artistic background to craft visually appealing
            interfaces. While I'm experienced in making simple, accessible
            designs, I can incorporate unconventional methods when appropriate.
          </p>
        </div>
      </TracingCanvas>
      <TracingCanvas
        style={{
          cursor: "none",
          width: "100%",
          height: "800px",
          zIndex: "13",
          boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
        }}
        options={{
          backgroundColor: "#FFF8E8",
          drawingColor: "#000000",
          mode: 0,
          rgbInc: [1, 0, 0.3],
          colorRange: 255,
          customFrameRate: 2,
          rootCount: 10,
          branchIterations: 3,
          branchStrokeWeight: 0.9,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "250px",
            left: "15%",
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            padding: "20px",
            border: "1px solid #ccc",
            zIndex: "20",
            boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            maxWidth: "500px",
            cursor: "default",
          }}
        >
          <h2
            style={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "#333",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            ... a Colaborator
          </h2>
          <p
            style={{
              fontSize: "18px",
              color: "#555",
              lineHeight: "1.5",
              textAlign: "justify",
            }}
          >
            Reliable and responsive. Throughout my career, I have honed the
            ability to respond to colleagues while keeping focus on my tasks. I
            have excelled in various social situations - from intern to a team
            leader.
          </p>
        </div>
      </TracingCanvas>
    </>
  );
}

export default AboutMe;
