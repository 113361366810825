import React from "react";
import { FaChevronDown } from "react-icons/fa"; // Import the downward arrow icon

function Cover() {
  return (
    <div
      style={{
        backgroundColor: "black",
        width: "100%",
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
        position: "relative",
        zIndex: "11",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          margin: "40px",
          color: "white",
          fontSize: "4em",
          fontWeight: "bold",
        }}
      >
        Hi, I am Danail Dimitrov,
      </h1>
      <h2
        style={{
          margin: "40px",
          color: "white",
          fontSize: "1.5em",
          fontWeight: "normal",
        }}
      >
        a Full-stack developer with over 3 years of experience.
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3
          style={{
            margin: "40px",
            color: "#FF204E",
            fontSize: "2em",
            lineHeight: "1.2",
          }}
        >
          Let me tell you about myself
        </h3>
        <FaChevronDown
          style={{
            fontSize: "1.5em",
            color: "white",
            animation: "bounce 1.7s infinite",
          }}
        />
      </div>
      <style>
        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-12px);
            }
            60% {
              transform: translateY(-10px);
            }
          }
        `}
      </style>
    </div>
  );
}

export default Cover;
