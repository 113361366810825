import React, { useRef, useEffect, useState } from "react";
import "../animations.css";

function SkillCard({ title, icon }) {
  const cardRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cardElement = cardRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (cardElement) {
      observer.observe(cardElement);
    }

    return () => {
      if (cardElement) {
        observer.unobserve(cardElement);
      }
    };
  }, []);

  return (
    <div
      ref={cardRef}
      className={`skill-card ${isVisible ? "visible" : ""}`}
      style={{
        backgroundColor: "#fff",
        padding: "10px",
        border: "1px solid #ccc",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        minWidth: "150px",
        height: "60px",
        textAlign: "center",
        cursor: "default",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        flexDirection: "row",
        marginRight: "2px",
      }}
    >
      {icon}
      <h3 style={{ margin: "0", color: "#333", fontSize: "20px" }}>{title}</h3>
    </div>
  );
}

export default SkillCard;
