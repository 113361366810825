import React from "react";
import {
  SiJavascript,
  SiReact,
  SiHtml5,
  SiCss3,
  SiSpringboot,
  SiMariadb,
  SiNextdotjs,
  SiKubernetes,
  SiDocker,
  SiGithub,
  SiKeycloak,
  SiApachekafka,
} from "react-icons/si";
import { FaJava, FaPython } from "react-icons/fa";
import SkillCard from "../components/SkillCard";
import "../animations.css";

function Skills() {
  return (
    <div
      style={{
        backgroundColor: "#FF204E",
        backgroundImage: `
          linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%),
          linear-gradient(225deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%),
          linear-gradient(315deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%),
          linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%)
        `,
        backgroundSize: "20px 20px",
        backgroundBlendMode: "overlay",
        width: "100%",
        height: "auto",
        padding: "40px 20px",
        position: "relative",
        zIndex: "12",
        color: "white",
        textAlign: "center",
      }}
    >
      <h2 style={{ marginBottom: "60px" }}>Technologies I have worked with:</h2>

      <div style={{ marginBottom: "40px" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <SkillCard
            title="JavaScript"
            icon={<SiJavascript size="2em" color="#f7df1e" />}
          />
          <SkillCard
            title="React"
            icon={<SiReact size="2em" color="#61dafb" />}
          />
          <SkillCard
            title="Next.js"
            icon={<SiNextdotjs size="2em" color="#000000" />}
          />
          <SkillCard
            title="HTML"
            icon={<SiHtml5 size="2em" color="#e34f26" />}
          />
          <SkillCard title="CSS" icon={<SiCss3 size="2em" color="#1572b6" />} />
        </div>
      </div>

      <div style={{ marginBottom: "40px" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <SkillCard
            title="Java"
            icon={<FaJava size="2em" color="#007396" />}
          />
          <SkillCard
            title="Spring Boot"
            icon={<SiSpringboot size="2em" color="#6DB33F" />}
          />
          <SkillCard
            title="MariaDB"
            icon={<SiMariadb size="2em" color="#003545" />}
          />
          <SkillCard
            title="Keycloak"
            icon={<SiKeycloak size="2em" color="#0086a5" />}
          />
        </div>
      </div>

      <div style={{ marginBottom: "40px" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <SkillCard
            title="GitHub Actions"
            icon={<SiGithub size="2em" color="#181717" />}
          />
          <SkillCard
            title="Kubernetes"
            icon={<SiKubernetes size="2em" color="#326ce5" />}
          />
          <SkillCard
            title="Docker"
            icon={<SiDocker size="2em" color="#2496ED" />}
          />
        </div>
      </div>

      <div style={{ marginBottom: "60px" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <SkillCard
            title="Kafka"
            icon={<SiApachekafka size="2em" color="#231F20" />}
          />
          <SkillCard
            icon={<FaPython size="2em" color="#306998" />}
            title="Python"
          />
          <SkillCard
            title="p5.js"
            icon={
              <img
                src="/p5Logo.png"
                alt="p5.js"
                style={{ width: "2em", height: "2em" }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Skills;
