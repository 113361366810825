import React from "react";
import Contact from "../sections/Contact";
import BackToHome from "../components/BackToHome";
import Footer from "../sections/Footer";

function NubedianWork() {
  return (
    <>
      <BackToHome />
      <div
        style={{
          backgroundColor: "#FFF8E8",
          width: "100%",
          padding: "100px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
          position: "relative",
          zIndex: "11",
        }}
      >
        <h1
          style={{
            marginBottom: "20px",
            color: "#FF204E",
            fontSize: "3rem",
            fontWeight: "bold",
            textAlign: "center",
            width: "60%",
          }}
        >
          Full-Stack Developer at Nubedian GmbH
        </h1>

        <div
          style={{
            width: "60%",
            height: "4px",
            backgroundColor: "#FF204E",
            marginBottom: "30px",
          }}
        />

        <div
          style={{
            width: "100%",
            maxWidth: "600px",
            height: "300px",
            backgroundColor: "#FFF8E8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <img
            src="/caseofrmBroadcasts-removebg-preview.png"
            alt="caseform broadcast table"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>

        <p
          style={{
            color: "#333",
            width: "45%",
            lineHeight: "1.6",
            marginBottom: "30px",
            marginTop: "40px",
            fontSize: "19px",
          }}
        >
          Nubedian specializes in healthcare products, and I was part of the
          team that maintained and developed a project called Caseform. This
          online application helps match hospital patients with suitable
          aftercare providers. Over my 2.5 years at Nubedian, I took on roles as
          a developer, reviewer, DevOps engineer, incident responder and mentor.
        </p>

        <h2
          style={{
            marginBottom: "30px",
            color: "black",

            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "left",
            width: "45%",
          }}
        >
          Notable Contributions:
        </h2>

        <ul
          style={{
            color: "#333",
            width: "45%",
            textAlign: "left",
            lineHeight: "1.6",
            margin: "0 auto",
            padding: "0",
            listStyleType: "none",
            fontSize: "19px",
          }}
        >
          <li style={{ marginBottom: "20px" }}>
            Developed and implemented server-side pagination, filtering, and
            search functionalities, creating reusable modules for both the
            backend and frontend, which were integrated across the majority of
            the application's table components.
          </li>
          <li style={{ marginBottom: "20px" }}>
            Discovered and investigated numerous security issues and bugs across
            the application, uncovering and addressing a wide range of defects,
            including three critical security vulnerabilities.
          </li>
          <li style={{ marginBottom: "20px" }}>
            Actively responded to incidents as a member of the On-Call Team,
            successfully deploying seven hotfixes to resolve critical issues.
          </li>
          <li style={{ marginBottom: "20px" }}>
            Served as a mentor to two new team members.
          </li>
        </ul>
      </div>

      <Contact />
      <Footer />
    </>
  );
}

export default NubedianWork;
