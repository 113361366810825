import React from "react";

function BackToHome() {
  const handleClick = () => {
    window.location.href = "/?scrollTo=previous-work";
  };

  return (
    <div
      onClick={handleClick}
      style={{
        position: "fixed",
        top: "20px",
        left: "20px",
        cursor: "pointer",
        zIndex: 1000,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        style={{ width: "30px", height: "30px", color: "#FF204E" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </div>
  );
}

export default BackToHome;
