import React, { useState } from "react";

function JobCard({ image, description, redirectUrl }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onClick={() => (window.location.href = redirectUrl)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered ? "rgba(0, 0, 0, 0.7)" : "#ffffff",
        width: "390px",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        position: "relative",
        transition: "background-color 0.3s ease",
      }}
    >
      <img
        src={image}
        alt=""
        style={{
          width: "100%",
          height: "190px",
          objectFit: "contain",
          borderRadius: "10px",
          filter: isHovered ? "brightness(0.5)" : "none",
          transition: "filter 0.3s ease",
        }}
      />

      {isHovered && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            fontSize: "19px",
            fontWeight: "bold",
            borderRadius: "10px",
            lineHeight: "1.8",
          }}
        >
          {description}
        </div>
      )}
    </div>
  );
}

export default JobCard;
