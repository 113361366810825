import "../App.css";
import React, { useEffect } from "react";
import AboutMe from "../sections/AboutMe";
import Cover from "../sections/Cover";
import Skills from "../sections/Skills";
import PreviousWork from "../sections/PreviousWork";
import Contact from "../sections/Contact";
import Footer from "../sections/Footer";

function MainPage() {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const scrollTo = queryParams.get("scrollTo");

    if (scrollTo === "previous-work") {
      const previousWorkSection = document.getElementById("previous-work");
      if (previousWorkSection) {
        previousWorkSection.scrollIntoView({ behavior: "smooth" });
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
    }
  }, []);
  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <Cover />
      <AboutMe />
      <Skills />
      <PreviousWork />
      <Contact />
      <Footer />
    </div>
  );
}

export default MainPage;
