import React from "react";
import Contact from "../sections/Contact";
import BackToHome from "../components/BackToHome";
import Footer from "../sections/Footer";

function MTLSWork() {
  return (
    <>
      <BackToHome />
      <div
        style={{
          backgroundColor: "#FFF8E8",
          width: "100%",
          padding: "100px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
          position: "relative",
          zIndex: "11",
        }}
      >
        <h1
          style={{
            marginBottom: "20px",
            color: "#FF204E",
            fontSize: "3rem",
            fontWeight: "bold",
            textAlign: "center",
            width: "60%",
          }}
        >
          Lead Developer of the M.T.L.S. project
        </h1>

        <div
          style={{
            width: "60%",
            height: "4px",
            backgroundColor: "#FF204E",
            marginBottom: "30px",
          }}
        />

        <div
          style={{
            width: "100%",
            maxWidth: "1000px",
            height: "500px",
            backgroundColor: "#FFF8E8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <img
            src="/mtlsDemo2.png"
            alt="mtls demonstration"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>

        <p
          style={{
            color: "#333",
            width: "45%",
            lineHeight: "1.6",
            marginBottom: "30px",
            marginTop: "40px",
            fontSize: "19px",
          }}
        >
          The Maritime Transport Logistics Simulator (M.T.L.S.) is a project
          funded by the Technical University of Varna, aimed at developing a
          simulation of the logistical processes involved in coordinating trade
          ships between ports. This simulator is designed to be used as a
          teaching tool in the university's Shipbuilding faculty. I led the
          development of the project from inception, building the frontend in
          React.js and the backend in Spring Boot with java. Alongside a
          colleague, I am currently implementing key features such as a
          real-time chat system, a file transfer service, role-based access for
          administrators and students, and in-app file editing capabilities.
        </p>
      </div>

      <Contact />
      <Footer />
    </>
  );
}

export default MTLSWork;
