import React from "react";

function Footer() {
  return (
    <footer
      style={{
        backgroundColor: "black",
        color: "white",
        padding: "20px 0",
        textAlign: "center",
      }}
    >
      <hr
        style={{
          border: "1px solid white",
          width: "80%",
          marginBottom: "20px",
        }}
      />

      <p style={{ fontSize: "18px", marginTop: "20px" }}>
        © Copyright 2024. Made by Danail Dimitrov.
      </p>
    </footer>
  );
}

export default Footer;
