import React from "react";

import JobCard from "../components/JobCard";
import SampleCard from "../components/SampleCard";

function PreviousWork() {
  return (
    <>
      <div
        id="previous-work"
        style={{
          backgroundColor: "#FFF8E8",
          width: "100%",
          padding: "50px 20px",
          position: "relative",
          zIndex: "11",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            color: "#FF204E",
            marginBottom: "40px",
          }}
        >
          My Work Experience:
        </h2>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <JobCard
            image="/nubedianCompanyLogoTransparent.png"
            description="Full-Stack Developer at Nubedian GmbH"
            redirectUrl={`/nubedian`}
          />
          <JobCard
            image="/mtlsLogoWhiteTransparent.png"
            description="Lead Developer for the M.T.L.S. project hosted by the Technical University of Varna"
            redirectUrl="/mtls"
          />
          <JobCard
            image="/creativeCodeLogoTransparent.png"
            description="Lead AI developer for the ChatBot project hosted by the Technical University of Varna"
            redirectUrl="/creativeCode"
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#FFF8E8",
          width: "100%",
          padding: "50px 20px",
          position: "relative",
          zIndex: "11",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <SampleCard
            image="/BrancingCanvas.gif"
            title="tracing-canvas"
            description="A Node.js module that creates an interactive canvas for drawing shapes. It features patterns that trace the movement of the mouse pointer, providing real-time visual effects."
            source="https://github.com/Danailnd/tracing-canvas"
            isGif={true}
          />
        </div>
      </div>
    </>
  );
}

export default PreviousWork;
