import React from "react";
import Contact from "../sections/Contact";
import BackToHome from "../components/BackToHome";
import Footer from "../sections/Footer";

function ChatBotWork() {
  return (
    <>
      <BackToHome />
      <div
        style={{
          backgroundColor: "#FFF8E8",
          width: "100%",
          padding: "100px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
          position: "relative",
          zIndex: "11",
        }}
      >
        <h1
          style={{
            marginBottom: "20px",
            color: "#FF204E",
            fontSize: "3rem",
            fontWeight: "bold",
            textAlign: "center",
            width: "60%",
          }}
        >
          Lead of the AI Development Team
        </h1>

        <div
          style={{
            width: "60%",
            height: "4px",
            backgroundColor: "#FF204E",
            marginBottom: "30px",
          }}
        />

        <p
          style={{
            color: "#333",
            width: "45%",
            lineHeight: "1.6",
            marginBottom: "30px",
            marginTop: "40px",
            fontSize: "19px",
          }}
        >
          Comming Soon.
        </p>
      </div>

      <Contact />
      <Footer />
    </>
  );
}

export default ChatBotWork;
