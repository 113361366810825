import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import NubedianWork from "./pages/NubedianWork";
import MTLSWork from "./pages/MTLSWork";
import ChatBotWork from "./pages/ChatBotWork";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/nubedian" element={<NubedianWork />} />
          <Route path="/mtls" element={<MTLSWork />} />
          <Route path="/creativeCode" element={<ChatBotWork />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
